import React, { useContext } from 'react';
import { Card, Button, Tab, Tabs } from 'react-bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { observer } from 'mobx-react-lite';
import { Context } from '..';
import { EXTRAORDINARY_COMMISSIONS_ROUTE } from '../utils/consts';
import { Link } from 'react-router-dom';
import extraord_commission_img from '../assets/extraord_commission.png';

const ResourcesServices = observer(() => {
    const { resourcesServices } = useContext(Context);
    return (
        <div className="px-xl-5 px-2 pb-5 border-bottom">
            <div className="block-title py-4">
                <span>Resurse și servicii</span>
            </div>
            <Tabs
                id="resources-services-tabs"
                className="mb-3 justify-content-center text-primary fw-bold"
                defaultActiveKey="local-documents"
            >
                <Tab eventKey="local-documents" title="Acte Locale">
                    <div className="d-flex flex-xl-row flex-column justify-content-around">
                        <Card className="shadow col-xl-4 col-md-12 col-12">
                            <div
                                className="rounded"
                                style={{
                                    background: `url(${extraord_commission_img})  center center/cover no-repeat`,
                                    minHeight: '30vh',
                                    maxHeight: '40vh',
                                    backgroundSize: 'cover !important',
                                    height: '100%',
                                }}
                            />
                            {/* <Card.Img variant='top' src='https://picsum.photos/300/400' /> */}
                            <Card.Body className="d-flex flex-column align-items-center justify-content-center bg-third">
                                <Card.Title>Comisii Extraordinare</Card.Title>
                                {/* <Card.Text>This is a longer card with supporting text below as a natural</Card.Text> */}
                                <Link
                                    className="bg-primary text-decoration-none text-white py-2 px-3 rounded d-flex align-items-center font-2"
                                    to={EXTRAORDINARY_COMMISSIONS_ROUTE}
                                >
                                    Vezi mai mult <i className="bi bi-arrow-right ps-3 icon-2" />
                                </Link>
                            </Card.Body>
                        </Card>

                        <div className="d-flex flex-xl-row flex-wrap col-xl-8 ps-xl-5 col-12 justify-content-center">
                            {resourcesServices.localDocs.map((item, idx) => (
                                <Link
                                    key={idx}
                                    to={item.link}
                                    className="d-flex flex-row mx-3 col-md-3 col-12 text-decoration-none text-primary"
                                >
                                    <div
                                        style={{ height: '5rem' }}
                                        className="d-flex align-items-center border-bottom col-12 truncate"
                                    >
                                        <i className={`bi ${item.icon} icon-4 px-3`} />
                                        <span>{item.title}</span>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="service-list" title="Lista cu servicii">
                    <div className="d-flex flex-xl-row flex-column justify-content-around">
                        <Card className="shadow col-xl-4 col-md-12 col-12">
                            <div
                                className="rounded"
                                style={{
                                    background: `url(${extraord_commission_img})  center center/cover no-repeat`,
                                    minHeight: '30vh',
                                    maxHeight: '40vh',
                                    backgroundSize: 'cover !important',
                                    height: '100%',
                                }}
                            />
                            {/* <Card.Img variant='top' src='https://picsum.photos/300/400' /> */}
                            <Card.Body className="d-flex flex-column align-items-center justify-content-center bg-third">
                                <Card.Title>Comisii Extraordinare</Card.Title>
                                {/* <Card.Text>This is a longer card with supporting text below as a natural</Card.Text> */}
                                <Link
                                    className="bg-primary text-decoration-none text-white py-2 px-3 rounded d-flex align-items-center font-2"
                                    to={EXTRAORDINARY_COMMISSIONS_ROUTE}
                                >
                                    Vezi mai mult <i className="bi bi-arrow-right ps-3 icon-2" />
                                </Link>
                            </Card.Body>
                        </Card>

                        <div className="d-flex flex-xl-row flex-wrap col-xl-8 ps-xl-5 col-12 justify-content-center">
                            {resourcesServices.serviceList.map((item, idx) => (
                                <Link
                                    key={idx}
                                    to={item.link}
                                    className="d-flex flex-row mx-3 col-md-3 col-12 text-decoration-none text-primary"
                                >
                                    <div
                                        style={{ height: '5rem' }}
                                        className="d-flex align-items-center border-bottom col-12 truncate"
                                    >
                                        <i className={`bi ${item.icon} icon-4 px-3`} />
                                        <span>{item.title}</span>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                </Tab>

                <Tab eventKey="investments" title="Investeste in localitate">
                    <div className="d-flex flex-xl-row flex-column justify-content-around">
                        <Card className="shadow col-xl-4 col-md-12 col-12">
                            <div
                                className="rounded"
                                style={{
                                    background: `url(${extraord_commission_img})  center center/cover no-repeat`,
                                    minHeight: '30vh',
                                    maxHeight: '40vh',
                                    backgroundSize: 'cover !important',
                                    height: '100%',
                                }}
                            />
                            {/* <Card.Img variant='top' src='https://picsum.photos/300/400' /> */}
                            <Card.Body className="d-flex flex-column align-items-center justify-content-center bg-third">
                                <Card.Title>Comisii Extraordinare</Card.Title>
                                {/* <Card.Text>This is a longer card with supporting text below as a natural</Card.Text> */}
                                <Link
                                    className="bg-primary text-decoration-none text-white py-2 px-3 rounded d-flex align-items-center font-2"
                                    to={EXTRAORDINARY_COMMISSIONS_ROUTE}
                                >
                                    Vezi mai mult <i className="bi bi-arrow-right ps-3 icon-2" />
                                </Link>
                            </Card.Body>
                        </Card>

                        <div className="d-flex flex-xl-row flex-wrap col-xl-8 ps-xl-5 col-12 justify-content-center">
                            {resourcesServices.investments.map((item, idx) => (
                                <Link
                                    key={idx}
                                    to={item.link}
                                    className="d-flex flex-row mx-3 col-md-3 col-12 text-decoration-none text-primary"
                                >
                                    <div
                                        style={{ height: '5rem' }}
                                        className="d-flex align-items-center border-bottom col-12 truncate"
                                    >
                                        <i className={`bi ${item.icon} icon-4 px-3`} />
                                        <span>{item.title}</span>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="cece" title="Alegeri">
                    <div className="d-flex flex-xl-row flex-column justify-content-around">
                        <Card className="shadow col-xl-4 col-md-12 col-12">
                            <div
                                className="rounded"
                                style={{
                                    background: `url(${extraord_commission_img})  center center/cover no-repeat`,
                                    minHeight: '30vh',
                                    maxHeight: '40vh',
                                    backgroundSize: 'cover !important',
                                    height: '100%',
                                }}
                            />
                            {/* <Card.Img variant='top' src='https://picsum.photos/300/400' /> */}
                            <Card.Body className="d-flex flex-column align-items-center justify-content-center bg-third">
                                <Card.Title>Comisii Extraordinare</Card.Title>
                                {/* <Card.Text>This is a longer card with supporting text below as a natural</Card.Text> */}
                                <Link
                                    className="bg-primary text-decoration-none text-white py-2 px-3 rounded d-flex align-items-center font-2"
                                    to={EXTRAORDINARY_COMMISSIONS_ROUTE}
                                >
                                    Vezi mai mult <i className="bi bi-arrow-right ps-3 icon-2" />
                                </Link>
                            </Card.Body>
                        </Card>

                        <div className="d-flex flex-xl-row flex-wrap col-xl-8 ps-xl-5 col-12 justify-content-center">
                            {resourcesServices.cece.map((item, idx) => (
                                <Link
                                    key={idx}
                                    to={item.link}
                                    className="d-flex flex-row mx-3 col-md-3 col-12 text-decoration-none text-primary"
                                >
                                    <div
                                        style={{ height: '5rem' }}
                                        className="d-flex align-items-center border-bottom col-12 truncate"
                                    >
                                        <i className={`bi ${item.icon} icon-4 px-3`} />
                                        <span>{item.title}</span>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                </Tab>
                {/* <Tab eventKey="other" title="Alte resurse"></Tab> */}
            </Tabs>
        </div>
    );
});

export default ResourcesServices;
