import React, { useContext, useEffect, useState } from 'react';
import { Tabs, Tab, Pagination } from 'react-bootstrap';
import { Context } from '..';
import Calendar from '../components/Calendar';
import { fetchAcquisition, fetchTenders, fetchVacancies } from '../http/informationProcessAPI';
import { Spinner, Accordion } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ACQUISITION_ROUTE, TENDERS_ROUTE, VACANCIES_ROUTE } from '../utils/consts';
import { observer } from 'mobx-react-lite';

const PublicInfo = observer(() => {
    const { informationProcess } = useContext(Context);
    const [loading, setLoading] = useState(true);

    const tenderPages = [];
    const acquisitionPages = [];
    const vacanciesPages = [];

    useEffect(() => {
        fetchTenders(1, 5)
            .then((data) => {
                informationProcess.setTenders(data.rows);
                informationProcess.setTendersTotalCount(data.count);
            })
            .then(() =>
                fetchAcquisition(1, 5)
                    .then((data) => {
                        informationProcess.setAcquisition(data.rows);
                        informationProcess.setAcquisitionTotalCount(data.count);
                    })
                    .then(() =>
                        fetchVacancies(1, 5).then((data) => {
                            informationProcess.setVacancies(data.rows);
                            informationProcess.setVacanciesTotalCount(data.count);
                        })
                    )
            )
            .finally(() => setLoading(false));
    }, [informationProcess]);

    useEffect(() => {
        fetchTenders(informationProcess.tendersPage, informationProcess.tendersLimit)
            .then((data) => {
                informationProcess.setTenders(data.rows);
                informationProcess.setTendersTotalCount(data.count);
            })
            .then(() =>
                fetchAcquisition(informationProcess.acquisitionPage, informationProcess.acquisitionLimit)
                    .then((data) => {
                        informationProcess.setAcquisition(data.rows);
                        informationProcess.setAcquisitionTotalCount(data.count);
                    })
                    .then(() =>
                        fetchVacancies(informationProcess.vacanciesPage, informationProcess.vacanciesLimit).then(
                            (data) => {
                                informationProcess.setVacancies(data.rows);
                                informationProcess.setVacanciesTotalCount(data.count);
                            }
                        )
                    )
            )
            .finally(() => setLoading(false));
    }, [informationProcess.tendersPage, informationProcess.acquisitionPage, informationProcess.vacanciesPage]);

    const tenderPageCount = Math.ceil(informationProcess.tendersTotalCount / informationProcess.tendersLimit);
    const acquisitionPageCount = Math.ceil(
        informationProcess.acquisitionTotalCount / informationProcess.acquisitionLimit
    );
    const vacanciesPageCount = Math.ceil(informationProcess.vacanciesTotalCount / informationProcess.vacanciesLimit);

    for (let i = 1; i <= tenderPageCount; i++) {
        tenderPages.push(i);
    }
    for (let i = 1; i <= acquisitionPageCount; i++) {
        acquisitionPages.push(i);
    }
    for (let i = 1; i <= vacanciesPageCount; i++) {
        vacanciesPages.push(i);
    }

    if (loading) {
        return (
            <div className="d-flex align-items-center justify-content-center py-5">
                <Spinner animation={'grow'} />
            </div>
        );
    }

    return (
        <div className="d-flex flex-md-row flex-column justify-content-around px-4 border-bottom">
            <div className="d-flex flex-column px-5 py-4 col-md-8">
                <div className="block-title pb-4">
                    <span>Informații publice</span>
                </div>
                <Tabs
                    defaultActiveKey="tenders"
                    id="uncontrolled-tab-example"
                    className="d-flex align-items-center justify-content-center mb-3 text-primary fw-bold"
                >
                    <Tab eventKey="tenders" title="Licitaţii şi achiziţii">
                        <div className="d-flex flex-column">
                            <Accordion className="pb-4">
                                {informationProcess.tenders.map((item, idx) =>
                                    item.tenders_files.length > 0 ? (
                                        <Accordion.Item
                                            eventKey={'tender-' + idx}
                                            key={'tender-' + idx}
                                            className="border-0"
                                        >
                                            <Accordion.Header>
                                                <Link
                                                    className="fw-bold ps-xl-1 font-1 text-primary text-justify pe-4 text-decoration-none"
                                                    to={TENDERS_ROUTE + '/' + item.id}
                                                >
                                                    {item.title}
                                                </Link>
                                            </Accordion.Header>
                                            <div className="d-flex justify-content-start py-1 ps-4">
                                                <span className="border rounded border-primary px-2 text-primary">
                                                    {item.date}
                                                </span>
                                            </div>
                                            <Accordion.Body>
                                                {item.tenders_files.map((sub_item, sub_idx) => (
                                                    <Link
                                                        to={process.env.REACT_APP_API_URL + sub_item.doc}
                                                        key={sub_idx}
                                                        className="text-primary text-decoration-none"
                                                    >
                                                        <div className="d-flex align-items-center">
                                                            <i
                                                                className={`bi bi-filetype-${sub_item.doc_type} icon-2 text-primary rounded ms-4 px-2 text-primary`}
                                                            />
                                                            <span
                                                                className="text-wrap"
                                                                style={{ textAlign: 'justify' }}
                                                            >
                                                                {sub_item.doc.substring(
                                                                    0,
                                                                    sub_item.doc.lastIndexOf('.')
                                                                )}
                                                            </span>
                                                        </div>
                                                    </Link>
                                                ))}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ) : (
                                        <div className="d-flex flex-column mb-4">
                                            <Link
                                                className="fw-bold font-1 ps-xl-4 text-primary text-justify pe-4 text-decoration-none"
                                                to={TENDERS_ROUTE + '/' + item.id}
                                            >
                                                {item.title}
                                            </Link>
                                            <div className="d-flex justify-content-start py-2 ps-4 pt-3">
                                                <span className="border rounded border-primary px-2 text-primary">
                                                    {item.date}
                                                </span>
                                            </div>
                                        </div>
                                    )
                                )}
                            </Accordion>
                            <Pagination className="d-flex justify-content-center align-items-center mt-5">
                                {tenderPages.map((page) => (
                                    <Pagination.Item
                                        key={page}
                                        active={informationProcess.tendersPage === page}
                                        onClick={() => informationProcess.setTendersPage(page)}
                                    >
                                        {page}
                                    </Pagination.Item>
                                ))}
                            </Pagination>
                        </div>
                    </Tab>
                    <Tab eventKey="acquisition" title="Planuri de achiziții">
                        <div className="d-flex flex-column">
                            <Accordion className="pb-4">
                                {informationProcess.acquisition.map((item, idx) =>
                                    item.acquisition_files.length > 0 ? (
                                        <Accordion.Item
                                            eventKey={'acquisition-' + idx}
                                            key={'acquisition-' + idx}
                                            className="border-0"
                                        >
                                            <Accordion.Header>
                                                <Link
                                                    className="fw-bold ps-xl-1 font-1 text-primary text-justify pe-4 text-decoration-none"
                                                    to={ACQUISITION_ROUTE + '/' + item.id}
                                                >
                                                    {item.title}
                                                </Link>
                                            </Accordion.Header>
                                            <div className="d-flex justify-content-start py-2 ps-4">
                                                <span className="border rounded border-primary px-2 text-primary">
                                                    {item.date}
                                                </span>
                                            </div>
                                            <Accordion.Body>
                                                {item.acquisition_files.map((sub_item, sub_idx) => (
                                                    <Link
                                                        to={process.env.REACT_APP_API_URL + sub_item.doc}
                                                        key={sub_idx}
                                                        className="text-primary text-decoration-none"
                                                    >
                                                        <div className="d-flex align-items-center">
                                                            <i
                                                                className={`bi bi-filetype-${sub_item.doc_type} icon-2 text-primary rounded ms-4 px-2 text-primary`}
                                                            />
                                                            <span
                                                                className="text-wrap"
                                                                style={{ textAlign: 'justify' }}
                                                            >
                                                                {sub_item.doc.substring(
                                                                    0,
                                                                    sub_item.doc.lastIndexOf('.')
                                                                )}
                                                            </span>
                                                        </div>
                                                    </Link>
                                                ))}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ) : (
                                        <div className="d-flex flex-column mb-4">
                                            <Link
                                                className="fw-bold font-1 ps-xl-4 text-primary text-justify pe-4 text-decoration-none"
                                                to={ACQUISITION_ROUTE + '/' + item.id}
                                            >
                                                {item.title}
                                            </Link>
                                            <div className="d-flex justify-content-start py-2 ps-4 pt-3">
                                                <span className="border rounded border-primary px-2 text-primary">
                                                    {item.date}
                                                </span>
                                            </div>
                                        </div>
                                    )
                                )}
                            </Accordion>
                            <Pagination className="d-flex justify-content-center align-items-center mt-5">
                                {acquisitionPages.map((page) => (
                                    <Pagination.Item
                                        key={page}
                                        active={informationProcess.acquisitionPage === page}
                                        onClick={() => informationProcess.setAcquisitionPage(page)}
                                    >
                                        {page}
                                    </Pagination.Item>
                                ))}
                            </Pagination>
                        </div>
                    </Tab>
                    <Tab eventKey="vacancies" title="Posturi vacante">
                        <div className="d-flex flex-column">
                            <Accordion className="pb-4">
                                {informationProcess.vacancies.map((item, idx) =>
                                    item.vacancies_files.length > 0 ? (
                                        <Accordion.Item
                                            eventKey={'vacancy-' + idx}
                                            key={'vacancy-' + idx}
                                            className="border-0"
                                        >
                                            <Accordion.Header>
                                                <Link
                                                    className="fw-bold font-1 text-primary text-justify pe-4 text-decoration-none"
                                                    to={VACANCIES_ROUTE + '/' + item.id}
                                                >
                                                    {item.title}
                                                </Link>
                                            </Accordion.Header>
                                            <div className="d-flex justify-content-start py-2 ps-4">
                                                <span className="border rounded border-primary px-2 text-primary">
                                                    {item.date}
                                                </span>
                                            </div>
                                            <Accordion.Body>
                                                {item.vacancies_files.map((sub_item, sub_idx) => (
                                                    <Link
                                                        to={process.env.REACT_APP_API_URL + sub_item.doc}
                                                        key={sub_idx}
                                                        className="text-primary text-decoration-none"
                                                    >
                                                        <div className="d-flex align-items-center">
                                                            <i
                                                                className={`bi bi-filetype-${sub_item.doc_type} icon-2 text-primary rounded ms-4 px-2 text-primary`}
                                                            />
                                                            <span
                                                                className="text-wrap"
                                                                style={{ textAlign: 'justify' }}
                                                            >
                                                                {sub_item.doc.substring(
                                                                    0,
                                                                    sub_item.doc.lastIndexOf('.')
                                                                )}
                                                            </span>
                                                        </div>
                                                    </Link>
                                                ))}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ) : (
                                        <div className="d-flex flex-column mb-4">
                                            <Link
                                                className="fw-bold font-1 ps-xl-4 text-primary text-justify pe-4 text-decoration-none"
                                                to={VACANCIES_ROUTE + '/' + item.id}
                                            >
                                                {item.title}
                                            </Link>
                                            <div className="d-flex justify-content-start py-2 ps-4 pt-3">
                                                <span className="border rounded border-primary px-2 text-primary">
                                                    {item.date}
                                                </span>
                                            </div>
                                        </div>
                                    )
                                )}
                            </Accordion>
                            <Pagination className="d-flex justify-content-center align-items-center mt-5">
                                {vacanciesPages.map((page) => (
                                    <Pagination.Item
                                        key={page}
                                        active={informationProcess.vacanciesPage === page}
                                        onClick={() => informationProcess.setVacanciesPage(page)}
                                    >
                                        {page}
                                    </Pagination.Item>
                                ))}
                            </Pagination>
                        </div>
                    </Tab>
                </Tabs>
            </div>
            <Calendar />
        </div>
    );
});

export default PublicInfo;
