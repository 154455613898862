import { useState, useEffect, useRef } from 'react';
import Calendar from '../../components/Calendar';
import { Image } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';
import { useParams, Link } from 'react-router-dom';
import { fetchOneRecommendations } from '../../http/informationProcessAPI';
import parse from 'html-react-parser';
import ShareButton from '../../components/UI/ShareButton';

const RecommendationsPage = () => {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const { id } = useParams();

    const printRef = useRef(null);
    const handlePrint = () => {
        const originalBodyContent = document.body.innerHTML;
        document.body.innerHTML = printRef.current.outerHTML;
        window.print();

        onafterprint = (e) => {
            console.log(e);

            document.body.innerHTML = originalBodyContent;
        };
        window.location.reload();
    };

    useEffect(() => {
        fetchOneRecommendations(id)
            .then((data) => {
                setData(data);
            })
            .finally(() => setLoading(false));
    }, [id]);
    if (loading) {
        return (
            <div className="d-flex align-items-center justify-content-center py-5">
                <Spinner animation={'grow'} />
            </div>
        );
    }

    return (
        <div>
            <div className="d-flex flex-xl-row flex-column justify-content-center px-xl-4 px-2">
                {data ? (
                    <div className="d-flex flex-column col-xl-8 px-xl-4" ref={printRef}>
                        <div className="py-4 block-title text-center">
                            <span className="text-primary">{data.title}</span>
                        </div>
                        <div className="d-flex flex-column justify-content-center pb-4">
                            {data.img ? (
                                <Image
                                    className="rounded"
                                    style={{
                                        maxHeight: '50vh',
                                        width: 'auto',
                                        aspectRatio: 1,
                                        objectFit: 'contain',
                                    }}
                                    src={process.env.REACT_APP_API_URL + data.img}
                                />
                            ) : null}
                            {data.description ? (
                                <span className="text-justify font-2 py-2">{parse(data.description)}</span>
                            ) : null}
                            {data.recommendations_files.map((sub_item, sub_idx) => (
                                <Link
                                    to={process.env.REACT_APP_API_URL + sub_item.doc}
                                    target="_blank"
                                    download
                                    key={sub_idx}
                                    className="text-primary text-decoration-none py-2"
                                >
                                    <div className="d-flex align-items-center">
                                        <i
                                            className={`bi bi-filetype-${sub_item.doc_type} icon-2 text-primary rounded ms-4 px-2 text-primary`}
                                        />
                                        <span className="text-wrap font-2" style={{ textAlign: 'justify' }}>
                                            {sub_item.doc.substring(0, sub_item.doc.lastIndexOf('.'))}
                                        </span>
                                    </div>
                                </Link>
                            ))}
                        </div>
                        <div className="d-flex flex-xl-row flex-column align-items-center justify-content-end w-100">
                            <div className="d-flex flex-row align-items-center justify-content-end ps-1">
                                <i
                                    role="button"
                                    onClick={() => handlePrint()}
                                    className="bi bi-printer text-primary icon-3"
                                    style={{
                                        fontWeight: '600 !important',
                                        WebkitTextStroke: 1,
                                    }}
                                />
                                <ShareButton />
                            </div>
                        </div>
                    </div>
                ) : null}
                <Calendar />
            </div>
        </div>
    );
};

export default RecommendationsPage;
