import { useState, useEffect } from 'react';
import Calendar from '../../components/Calendar';
import { Image } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { fetchOneMeetings } from '../../http/navLinksAPI';
import parse from 'html-react-parser';
import ShareButton from '../../components/UI/ShareButton';

const MeetingsPage = () => {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const { id } = useParams();

    useEffect(() => {
        fetchOneMeetings(id)
            .then((data) => {
                setData(data);
            })
            .finally(() => setLoading(false));
    }, [id]);
    if (loading) {
        return (
            <div className="d-flex align-items-center justify-content-center py-5">
                <Spinner animation={'grow'} />
            </div>
        );
    }

    return (
        <div>
            <div className="d-flex flex-xl-row flex-column justify-content-center px-xl-4 px-2">
                {data ? (
                    <div className="d-flex flex-column col-xl-8 px-xl-4">
                        <div className="py-4 block-title text-center">
                            <span className="text-primary">{data.title}</span>
                        </div>
                        <div className="d-flex flex-column justify-content-center pb-4">
                            {data.img ? (
                                <Image
                                    className="rounded"
                                    style={{
                                        maxHeight: '50vh',
                                        width: 'auto',
                                        aspectRatio: 1,
                                        objectFit: 'contain',
                                    }}
                                    src={process.env.REACT_APP_API_URL + data.img}
                                />
                            ) : null}
                            <span className="text-justify font-2 pb-2">{parse(data.description)}</span>
                            {data.meetings_files.map((sub_item, sub_idx) => (
                                <a
                                    href={process.env.REACT_APP_API_URL + sub_item.doc}
                                    target="_blank"
                                    download
                                    key={sub_idx}
                                    className="text-primary text-decoration-none py-2"
                                >
                                    <div className="d-flex align-items-center">
                                        <i
                                            className={`bi bi-filetype-${sub_item.doc_type} icon-2 text-primary rounded ms-4 px-2 text-primary`}
                                        />
                                        <span className="text-wrap font-2" style={{ textAlign: 'justify' }}>
                                            {sub_item.doc.substring(0, sub_item.doc.lastIndexOf('.'))}
                                        </span>
                                    </div>
                                </a>
                            ))}
                        </div>
                        <div className="d-flex flex-xl-row flex-column align-items-center justify-content-between w-100 py-3 ">
                            <span className="border border-primary rounded text-primary my-1 p-2 fw-bold">
                                Autor: {data.author}
                            </span>
                            <span className="border border-primary rounded text-primary my-1 p-2 fw-bold">
                                {data.date}
                            </span>
                            <div className="d-flex flex-row align-items-center justify-content-end my-1 ps-xl-1">
                                <ShareButton />
                            </div>
                        </div>
                    </div>
                ) : null}
                <Calendar />
            </div>
        </div>
    );
};

export default MeetingsPage;
