import { useEffect, useState, useContext } from 'react';
import { Form, Spinner, Accordion, Card, useAccordionButton, Tabs, Tab } from 'react-bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { observer } from 'mobx-react-lite';
import { Context } from '../../index';
import { useNavigate } from 'react-router-dom';
import { ACQUISITION_ROUTE, TENDERS_ROUTE, VACANCIES_ROUTE } from '../../utils/consts';
import {
    fetchAcquisition,
    fetchTenders,
    fetchVacancies,
    removeOneAcquisition,
    removeOneAcquisitionFile,
    removeOneTenders,
    removeOneTendersFile,
    removeOneVacancies,
    removeOneVacanciesFile,
    updateOneAcquisition,
    updateOneTenders,
    updateOneVacancies,
} from '../../http/informationProcessAPI';
import AddPublicInformationModal from './Modals/AddPublicInformationModal';
import parse from 'html-react-parser';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const PublicInformationAdmin = observer(() => {
    const { informationProcess } = useContext(Context);
    const [show, setShow] = useState(false);

    const [loading, setLoading] = useState(true);
    const [selectedTab, setSelectedTab] = useState('Licitaţii şi achiziţii');

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [img, setImg] = useState([null]);
    const [files, setFiles] = useState([null]);

    const navigate = useNavigate();

    const CustomToggle = ({ children, eventKey }) => {
        const decoratedOnClick = useAccordionButton(eventKey);

        return (
            <button
                className="btn btn-primary d-flex align-items-center mb-xl-1 mx-xl-0 mx-2"
                onClick={decoratedOnClick}
            >
                {children}
            </button>
        );
    };

    const editTender = (data) => {
        const id = data.id;
        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        formData.append('img', img);
        for (const file of files) {
            formData.append('files', file);
        }
        updateOneTenders(formData, id).then(() => window.location.reload());
    };

    const editAcquisition = (data) => {
        const id = data.id;
        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        formData.append('img', img);
        for (const file of files) {
            formData.append('files', file);
        }
        updateOneAcquisition(formData, id).then(() => window.location.reload());
    };

    const editVacancies = (data) => {
        const id = data.id;
        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        formData.append('img', img);
        for (const file of files) {
            formData.append('files', file);
        }
        updateOneVacancies(formData, id).then(() => window.location.reload());
    };

    const removeTender = (item) => {
        removeOneTenders(item.id).then(() => window.location.reload());
    };
    const removeTendersFile = (item) => {
        removeOneTendersFile(item.id).then(() => window.location.reload());
    };

    const removeAcquisition = (item) => {
        removeOneAcquisition(item.id).then(() => window.location.reload());
    };
    const removeAcquisitionFile = (item) => {
        removeOneAcquisitionFile(item.id).then(() => window.location.reload());
    };

    const removeVacancies = (item) => {
        removeOneVacancies(item.id).then(() => window.location.reload());
    };
    const removeVacanciesFile = (item) => {
        removeOneVacanciesFile(item.id).then(() => window.location.reload());
    };

    const selectFile = (e) => {
        setImg(e.target.files[0]);
    };

    const selectMultipleFiles = (e) => {
        setFiles(e.target.files);
    };

    const modules = {
        toolbar: [
            [{ size: ['small', false, 'large', 'huge'] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }, { align: [] }],
            ['link', 'image'],
            [
                {
                    color: [
                        '#000000',
                        '#e60000',
                        '#ff9900',
                        '#ffff00',
                        '#008a00',
                        '#0066cc',
                        '#9933ff',
                        '#ffffff',
                        '#facccc',
                        '#ffebcc',
                        '#ffffcc',
                        '#cce8cc',
                        '#cce0f5',
                        '#ebd6ff',
                        '#bbbbbb',
                        '#f06666',
                        '#ffc266',
                        '#ffff66',
                        '#66b966',
                        '#66a3e0',
                        '#c285ff',
                        '#888888',
                        '#a10000',
                        '#b26b00',
                        '#b2b200',
                        '#006100',
                        '#0047b2',
                        '#6b24b2',
                        '#444444',
                        '#5c0000',
                        '#663d00',
                        '#666600',
                        '#003700',
                        '#002966',
                        '#3d1466',
                        'custom-color',
                    ],
                },
            ],
        ],
    };

    const formats = [
        'header',
        'height',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'color',
        'bullet',
        'indent',
        'link',
        'image',
        'align',
        'size',
    ];

    useEffect(() => {
        fetchTenders().then((data) => {
            informationProcess.setTenders(data);
        });
        fetchAcquisition().then((data) => {
            informationProcess.setAcquisition(data);
        });
        fetchVacancies()
            .then((data) => {
                informationProcess.setVacancies(data);
            })
            .finally(() => setLoading(false));
    }, []);

    if (loading) {
        return (
            <div className="d-flex align-items-center justify-content-center py-5">
                <Spinner animation={'grow'} />
            </div>
        );
    }
    return (
        <div className="d-flex flex-column bg-white r-corner-radius w-100">
            <div className="d-flex justify-content-between align-items-center font-2 fw-bold text-primary shadow-sm ps-3">
                <span>Informații publice</span>
                <button
                    className="btn btn-primary r-u-corner-radius"
                    style={{ height: 70, width: 70 }}
                    onClick={() => setShow(true)}
                >
                    <i className="bi bi-plus-circle icon-2" />
                </button>
            </div>
            <div className="d-flex flex-column px-2 pt-3">
                <Tabs
                    defaultActiveKey="Licitaţii şi achiziţii"
                    id="uncontrolled-tab-example"
                    className="d-flex justify-content-center mb-3 text-primary fw-bold"
                    onSelect={(e) => setSelectedTab(e)}
                >
                    <Tab eventKey="Licitaţii şi achiziţii" title="Licitaţii şi achiziţii">
                        <div className="d-flex flex-column">
                            <Accordion className="pb-4">
                                {informationProcess.tenders.count > 0
                                    ? informationProcess.tenders.rows.map((item, idx) => (
                                          <div key={idx} className="shadow-sm border-0 my-2 px-4">
                                              <div className="d-flex flex-column">
                                                  <div className="d-flex flex-xl-row flex-column align-items-center justify-content-between  p-3">
                                                      <div>
                                                          <a
                                                              role="button"
                                                              onClick={() => navigate(TENDERS_ROUTE + '/' + item.id)}
                                                              className="text-decoration-none text-primary"
                                                          >
                                                              <Card.Title className="truncate">{item.title}</Card.Title>
                                                          </a>
                                                          {item.description ? (
                                                              <div className="truncate">{parse(item.description)}</div>
                                                          ) : null}
                                                      </div>
                                                      <div className="d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3">
                                                          <CustomToggle
                                                              eventKey={idx}
                                                              onClick={() => {
                                                                  useAccordionButton({ idx });
                                                              }}
                                                          >
                                                              <i className="bi bi-pencil-square icon-2 pe-2" />
                                                              <span>Edit</span>
                                                          </CustomToggle>
                                                          <button
                                                              className="btn btn-primary d-flex align-items-center mx-xl-0 mx-2"
                                                              onClick={() => removeTender(item)}
                                                          >
                                                              <i className="bi bi-x-circle icon-2 pe-2" />
                                                              <span>Remove</span>
                                                          </button>
                                                      </div>
                                                  </div>
                                              </div>
                                              <Accordion.Collapse eventKey={idx}>
                                                  <div className="d-flex flex-column justify-content-around px-4 py-4 border-top">
                                                      <div className="d-flex flex-column justify-content-around px-4 py-4">
                                                          <span className="text-primary  font-2 py-2 ps-1">
                                                              Denumire
                                                          </span>
                                                          <Form.Control
                                                              aria-label="Title"
                                                              placeholder={item.title}
                                                              value={title}
                                                              onChange={(e) => {
                                                                  setTitle(e.target.value);
                                                              }}
                                                          />
                                                          <span className="text-primary  font-2 py-2 ps-1">
                                                              Descriere
                                                          </span>
                                                          <ReactQuill
                                                              theme="snow"
                                                              placeholder={item.description}
                                                              value={description}
                                                              modules={modules}
                                                              formats={formats}
                                                              onChange={setDescription}
                                                          />
                                                          <span className="text-primary  font-2 py-2 ps-1">
                                                              Fotografie
                                                          </span>
                                                          <Form.Control
                                                              type={'file'}
                                                              onChange={selectFile}
                                                              aria-label="PageTitle"
                                                              aria-describedby="basic-addon"
                                                          />
                                                          <span className="text-primary  font-2 py-2 ps-1">
                                                              Documente
                                                          </span>
                                                          <Form.Control
                                                              type={'file'}
                                                              onChange={selectMultipleFiles}
                                                              aria-label="PageTitle"
                                                              aria-describedby="basic-addon"
                                                              multiple
                                                          />
                                                          {item.tenders_files.length > 0
                                                              ? item.tenders_files.map((sub_item, sub_idx) => (
                                                                    <div
                                                                        key={sub_idx}
                                                                        className="d-flex flex-row justify-content-around align-items-center text-primary text-decoration-none py-4"
                                                                    >
                                                                        <div className="d-flex align-items-center">
                                                                            <i
                                                                                className={`bi bi-filetype-${sub_item.doc_type} icon-2 text-primary rounded ms-4 px-2 text-primary`}
                                                                            />
                                                                            <a
                                                                                href={
                                                                                    process.env.REACT_APP_API_URL +
                                                                                    sub_item.doc
                                                                                }
                                                                            >
                                                                                <span
                                                                                    className="text-wrap"
                                                                                    style={{ textAlign: 'justify' }}
                                                                                >
                                                                                    {sub_item.doc.substring(
                                                                                        0,
                                                                                        sub_item.doc.lastIndexOf('.')
                                                                                    )}
                                                                                </span>
                                                                            </a>
                                                                        </div>
                                                                        <button
                                                                            className="btn btn-primary"
                                                                            onClick={() => removeTendersFile(sub_item)}
                                                                        >
                                                                            <i className="bi bi-x-circle font-2"></i>
                                                                        </button>
                                                                    </div>
                                                                ))
                                                              : null}
                                                      </div>
                                                      <button
                                                          className="btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4"
                                                          onClick={() => editTender(item)}
                                                      >
                                                          <i className="bi bi-check icon-2 pe-2" />
                                                          <span>Confirm</span>
                                                      </button>
                                                  </div>
                                              </Accordion.Collapse>
                                          </div>
                                      ))
                                    : null}
                            </Accordion>
                        </div>
                    </Tab>
                    <Tab eventKey="Planuri de achiziții" title="Planuri de achiziții">
                        <div className="d-flex flex-column">
                            <Accordion className="pb-4">
                                {informationProcess.acquisition.count > 0
                                    ? informationProcess.acquisition.rows.map((item, idx) => (
                                          <div key={idx} className="shadow-sm border-0 my-2 px-4">
                                              <div className="d-flex flex-column">
                                                  <div className="d-flex flex-xl-row flex-column align-items-center justify-content-between  p-3">
                                                      <div>
                                                          <a
                                                              role="button"
                                                              onClick={() =>
                                                                  navigate(ACQUISITION_ROUTE + '/' + item.id)
                                                              }
                                                              className="text-decoration-none text-primary"
                                                          >
                                                              <Card.Title className="truncate">{item.title}</Card.Title>
                                                          </a>
                                                          {item.description ? (
                                                              <div className="truncate">{parse(item.description)}</div>
                                                          ) : null}
                                                      </div>
                                                      <div className="d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3">
                                                          <CustomToggle
                                                              eventKey={idx}
                                                              onClick={() => {
                                                                  useAccordionButton({ idx });
                                                              }}
                                                          >
                                                              <i className="bi bi-pencil-square icon-2 pe-2" />
                                                              <span>Edit</span>
                                                          </CustomToggle>
                                                          <button
                                                              className="btn btn-primary d-flex align-items-center mx-xl-0 mx-2"
                                                              onClick={() => removeAcquisition(item)}
                                                          >
                                                              <i className="bi bi-x-circle icon-2 pe-2" />
                                                              <span>Remove</span>
                                                          </button>
                                                      </div>
                                                  </div>
                                              </div>
                                              <Accordion.Collapse eventKey={idx}>
                                                  <div className="d-flex flex-column justify-content-around px-4 py-4 border-top">
                                                      <div className="d-flex flex-column justify-content-around px-4 py-4">
                                                          <span className="text-primary  font-2 py-2 ps-1">
                                                              Denumire
                                                          </span>
                                                          <Form.Control
                                                              aria-label="Title"
                                                              placeholder={item.title}
                                                              value={title}
                                                              onChange={(e) => {
                                                                  setTitle(e.target.value);
                                                              }}
                                                          />
                                                          <span className="text-primary  font-2 py-2 ps-1">
                                                              Descriere
                                                          </span>
                                                          <ReactQuill
                                                              theme="snow"
                                                              placeholder={item.description}
                                                              value={description}
                                                              modules={modules}
                                                              formats={formats}
                                                              onChange={setDescription}
                                                          />
                                                          <span className="text-primary  font-2 py-2 ps-1">
                                                              Fotografie
                                                          </span>
                                                          <Form.Control
                                                              type={'file'}
                                                              onChange={selectFile}
                                                              aria-label="PageTitle"
                                                              aria-describedby="basic-addon"
                                                          />
                                                          <span className="text-primary  font-2 py-2 ps-1">
                                                              Documente
                                                          </span>
                                                          <Form.Control
                                                              type={'file'}
                                                              onChange={selectMultipleFiles}
                                                              aria-label="PageTitle"
                                                              aria-describedby="basic-addon"
                                                              multiple
                                                          />
                                                          {item.acquisition_files.length > 0
                                                              ? item.acquisition_files.map((sub_item, sub_idx) => (
                                                                    <div
                                                                        key={sub_idx}
                                                                        className="d-flex flex-row justify-content-around align-items-center text-primary text-decoration-none py-4"
                                                                    >
                                                                        <div className="d-flex align-items-center">
                                                                            <i
                                                                                className={`bi bi-filetype-${sub_item.doc_type} icon-2 text-primary rounded ms-4 px-2 text-primary`}
                                                                            />
                                                                            <a
                                                                                href={
                                                                                    process.env.REACT_APP_API_URL +
                                                                                    sub_item.doc
                                                                                }
                                                                            >
                                                                                <span
                                                                                    className="text-wrap"
                                                                                    style={{ textAlign: 'justify' }}
                                                                                >
                                                                                    {sub_item.doc.substring(
                                                                                        0,
                                                                                        sub_item.doc.lastIndexOf('.')
                                                                                    )}
                                                                                </span>
                                                                            </a>
                                                                        </div>
                                                                        <button
                                                                            className="btn btn-primary"
                                                                            onClick={() =>
                                                                                removeAcquisitionFile(sub_item)
                                                                            }
                                                                        >
                                                                            <i className="bi bi-x-circle font-2"></i>
                                                                        </button>
                                                                    </div>
                                                                ))
                                                              : null}
                                                      </div>
                                                      <button
                                                          className="btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4"
                                                          onClick={() => editAcquisition(item)}
                                                      >
                                                          <i className="bi bi-check icon-2 pe-2" />
                                                          <span>Confirm</span>
                                                      </button>
                                                  </div>
                                              </Accordion.Collapse>
                                          </div>
                                      ))
                                    : null}
                            </Accordion>
                        </div>
                    </Tab>
                    <Tab eventKey="Posturi vacante" title="Posturi vacante">
                        <div className="d-flex flex-column">
                            <Accordion className="pb-4">
                                {informationProcess.vacancies.count > 0
                                    ? informationProcess.vacancies.rows.map((item, idx) => (
                                          <div key={idx} className="shadow-sm border-0 my-2 px-4">
                                              <div className="d-flex flex-column">
                                                  <div className="d-flex flex-xl-row flex-column align-items-center justify-content-between  p-3">
                                                      <div>
                                                          <a
                                                              role="button"
                                                              onClick={() => navigate(VACANCIES_ROUTE + '/' + item.id)}
                                                              className="text-decoration-none text-primary"
                                                          >
                                                              <Card.Title className="truncate">{item.title}</Card.Title>
                                                          </a>
                                                          {item.description ? (
                                                              <div className="truncate">{parse(item.description)}</div>
                                                          ) : null}
                                                      </div>
                                                      <div className="d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3">
                                                          <CustomToggle
                                                              eventKey={idx}
                                                              onClick={() => {
                                                                  useAccordionButton({ idx });
                                                              }}
                                                          >
                                                              <i className="bi bi-pencil-square icon-2 pe-2" />
                                                              <span>Edit</span>
                                                          </CustomToggle>
                                                          <button
                                                              className="btn btn-primary d-flex align-items-center mx-xl-0 mx-2"
                                                              onClick={() => removeVacancies(item)}
                                                          >
                                                              <i className="bi bi-x-circle icon-2 pe-2" />
                                                              <span>Remove</span>
                                                          </button>
                                                      </div>
                                                  </div>
                                              </div>
                                              <Accordion.Collapse eventKey={idx}>
                                                  <div className="d-flex flex-column justify-content-around px-4 py-4 border-top">
                                                      <div className="d-flex flex-column justify-content-around px-4 py-4">
                                                          <span className="text-primary  font-2 py-2 ps-1">
                                                              Denumire
                                                          </span>
                                                          <Form.Control
                                                              aria-label="Title"
                                                              placeholder={item.title}
                                                              value={title}
                                                              onChange={(e) => {
                                                                  setTitle(e.target.value);
                                                              }}
                                                          />
                                                          <span className="text-primary  font-2 py-2 ps-1">
                                                              Descriere
                                                          </span>
                                                          <ReactQuill
                                                              theme="snow"
                                                              placeholder={item.description}
                                                              value={description}
                                                              modules={modules}
                                                              formats={formats}
                                                              onChange={setDescription}
                                                          />
                                                          <span className="text-primary  font-2 py-2 ps-1">
                                                              Fotografie
                                                          </span>
                                                          <Form.Control
                                                              type={'file'}
                                                              onChange={selectFile}
                                                              aria-label="PageTitle"
                                                              aria-describedby="basic-addon"
                                                          />
                                                          <span className="text-primary  font-2 py-2 ps-1">
                                                              Documente
                                                          </span>
                                                          <Form.Control
                                                              type={'file'}
                                                              onChange={selectMultipleFiles}
                                                              aria-label="PageTitle"
                                                              aria-describedby="basic-addon"
                                                              multiple
                                                          />
                                                          {item.vacancies_files.length > 0
                                                              ? item.vacancies_files.map((sub_item, sub_idx) => (
                                                                    <div
                                                                        key={sub_idx}
                                                                        className="d-flex flex-row justify-content-around align-items-center text-primary text-decoration-none py-4"
                                                                    >
                                                                        <div className="d-flex align-items-center">
                                                                            <i
                                                                                className={`bi bi-filetype-${sub_item.doc_type} icon-2 text-primary rounded ms-4 px-2 text-primary`}
                                                                            />
                                                                            <a
                                                                                href={
                                                                                    process.env.REACT_APP_API_URL +
                                                                                    sub_item.doc
                                                                                }
                                                                            >
                                                                                <span
                                                                                    className="text-wrap"
                                                                                    style={{ textAlign: 'justify' }}
                                                                                >
                                                                                    {sub_item.doc.substring(
                                                                                        0,
                                                                                        sub_item.doc.lastIndexOf('.')
                                                                                    )}
                                                                                </span>
                                                                            </a>
                                                                        </div>
                                                                        <button
                                                                            className="btn btn-primary"
                                                                            onClick={() =>
                                                                                removeVacanciesFile(sub_item)
                                                                            }
                                                                        >
                                                                            <i className="bi bi-x-circle font-2"></i>
                                                                        </button>
                                                                    </div>
                                                                ))
                                                              : null}
                                                      </div>
                                                      <button
                                                          className="btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4"
                                                          onClick={() => editVacancies(item)}
                                                      >
                                                          <i className="bi bi-check icon-2 pe-2" />
                                                          <span>Confirm</span>
                                                      </button>
                                                  </div>
                                              </Accordion.Collapse>
                                          </div>
                                      ))
                                    : null}
                            </Accordion>
                        </div>
                    </Tab>
                </Tabs>
            </div>
            <AddPublicInformationModal show={show} onHide={() => setShow(false)} selectedTab={selectedTab} />
        </div>
    );
});

export default PublicInformationAdmin;
