import React, { useContext, useState, useEffect, useRef } from 'react';
import Calendar from '../components/Calendar';
import { Image } from 'react-bootstrap';
import { Context } from '..';
import { fetchInitiatingPublicPrivatePartnerships } from '../http/resourcesServicesAPI';
import { Spinner } from 'react-bootstrap';
import parse from 'html-react-parser';
import ShareButton from '../components/UI/ShareButton';

const InitiatingPublicPrivatePartnerships = () => {
    const { resourcesServices } = useContext(Context);

    const [loading, setLoading] = useState(true);

    const printRef = useRef(null);
    const handlePrint = () => {
        const originalBodyContent = document.body.innerHTML;
        document.body.innerHTML = printRef.current.outerHTML;
        window.print();

        onafterprint = (e) => {
            console.log(e);

            document.body.innerHTML = originalBodyContent;
            window.location.reload();
        };
    };

    useEffect(() => {
        fetchInitiatingPublicPrivatePartnerships()
            .then((data) => {
                resourcesServices.setInitiatingPublicPrivatePartnerships(data);
            })
            .finally(() => setLoading(false));
    }, []);
    if (loading) {
        return (
            <div className="d-flex align-items-center justify-content-center py-5">
                <Spinner animation={'grow'} />
            </div>
        );
    }

    return (
        <div>
            <div className="d-flex flex-xl-row flex-column justify-content-center px-xl-4">
                <div className="d-flex flex-column col-xl-8 col-12 px-xl-4 px-2" ref={printRef}>
                    <div className="py-4 block-title text-center">
                        <span className="text-primary">
                            Proiecte investiționale propuse spre implementare prin Parteneriat Public Privat în
                            colaborare cu Consiliul Raional Edineț
                        </span>
                    </div>
                    <div className="d-flex flex-column justify-content-center">
                        <Image
                            className="rounded"
                            style={{
                                maxHeight: '50vh',
                                width: 'auto',
                                aspectRatio: 1,
                                objectFit: 'contain',
                            }}
                            src={
                                resourcesServices.initiatingPublicPrivatePartnerships.count > 0
                                    ? process.env.REACT_APP_API_URL +
                                      resourcesServices.initiatingPublicPrivatePartnerships.rows[0].img
                                    : null
                            }
                        />
                        <span className="text-justify font-2 py-2 px-2">
                            {resourcesServices.initiatingPublicPrivatePartnerships.count > 0
                                ? parse(resourcesServices.initiatingPublicPrivatePartnerships.rows[0].description)
                                : null}
                        </span>
                        {resourcesServices.initiatingPublicPrivatePartnerships.count > 0
                            ? resourcesServices.initiatingPublicPrivatePartnerships.rows.map((item, idx) =>
                                  item.initiating_public_private_partnerships_files.map((sub_item, sub_idx) => (
                                      <a
                                          href={process.env.REACT_APP_API_URL + sub_item.doc}
                                          target="_blank"
                                          download
                                          key={sub_idx}
                                          className="text-primary text-decoration-none py-2"
                                      >
                                          <div className="d-flex align-items-center">
                                              <i
                                                  className={`bi bi-filetype-${sub_item.doc_type} icon-2 text-primary rounded ms-4 px-2 text-primary`}
                                              />
                                              <span className="text-wrap" style={{ textAlign: 'justify' }}>
                                                  {sub_item.doc.substring(0, sub_item.doc.lastIndexOf('.'))}
                                              </span>
                                          </div>
                                      </a>
                                  ))
                              )
                            : null}
                    </div>

                    <div className="d-flex flex-xl-row flex-column align-items-center justify-content-end w-100">
                        <div className="d-flex flex-row align-items-center justify-content-end ps-1">
                            <i
                                role="button"
                                onClick={() => handlePrint()}
                                className="bi bi-printer text-primary icon-3"
                                style={{
                                    fontWeight: '600 !important',
                                    WebkitTextStroke: 1,
                                }}
                            />
                            <ShareButton />
                        </div>
                    </div>
                </div>
                <Calendar />
            </div>
        </div>
    );
};

export default InitiatingPublicPrivatePartnerships;
